<template>
  <div class="installation-item">
    <h1>{{ title }}</h1>
    <div>
      <vue3-markdown-it v-if="location" :source="location" class="show-loc" />
    </div>
    <div>
      <vue3-markdown-it :source="dates" class="show-dates" />
    </div>
    <div class="writeup-credits-container">
      <div class="show-credits" :class="credits ? '' : 'none'">
        <div v-if="credits" class="credits-header">Credits</div>
        <vue3-markdown-it v-if="credits" :source="credits" />
      </div>
      <div class="md-content writeup">
        <vue3-markdown-it :source="writeup" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InstallationItem",
  props: {
    title: String,
    location: String,
    dates: String,
    credits: String,
    writeup: String,
  },
};
</script>

<style lang="scss">
.installation-item {
  border: 1px solid fuschia;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  padding: 2rem;
  font-size: 1.6rem;
  h1 {
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .show-loc,
  .show-dates {
    p {
      font-size: 1.8rem;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }
  .show-dates {
    margin-bottom: 2rem;
  }
  .writeup-credits-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .show-credits {
      width: 100%;
      background-color: rgb(230, 230, 230);
      padding: 2rem;
      margin-bottom: 4rem;
      &.none {
        padding: 0;
        background-color: transparent;
      }
      .credits-header {
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 1.6rem;
      }
      p {
        color: black;
        em {
          margin-top: 1.2rem;
          color: #222;
        }
      }
    }
    .writeup {
      width: 100%;
      margin: 0;
      img {
        width: 100%;
        max-width: 800px;
      }
    }
  }

  /* Larger than tablet */
  @media (min-width: 750px) {
    .writeup-credits-container {
      flex-direction: row-reverse;
      .show-credits {
        width: 35rem;
      }
      .writeup {
        margin: 0 2rem 0 0;
      }
    }
  }
}
</style>
