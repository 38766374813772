<template>
  <div class="page installations">
    <div class="breadcrumbs">
      <a href="/">home</a> » <a href="/installations">installations</a> »
      {{ $route.params.slug }}
    </div>
    <!-- Start Installations Gallery -->
    <!-- <vue3-markdown-it :source="pageContent" class="installations-content" /> -->
    <div class="installations-content">
      <InstallationItem
        :key="installation.id"
        :title="installation.title"
        :location="installation.location"
        :dates="installation.dates"
        :credits="installation.credits"
        :writeup="installation.writeup"
      />
    </div>
    <!-- End Installations Gallery -->
    <div class="return-to-index">
      <a href="/installations" class="button cta-black"
        >View All Installations</a
      >
    </div>
  </div>
</template>

<script>
// import LinkPrompter from "@/components/LinkPrompter.vue";
import InstallationItem from "@/components/InstallationItem.vue";
import installationsJSON from "@/page-contents/installations.json";
// import content from "!raw-loader!../page-contents/installations.md";

import getData from "@/services/dc-service.js";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Installations`,
      description: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => `${siteData.title}`),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/installations`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "Installation",
  components: {
    // LinkPrompter,
    InstallationItem,
  },
  data: function () {
    return {
      installation: {},
      slug: null,
    };
  },
  methods: {
    fetchData() {
      console.log("Getting data from CMS.");
      getData("installations").then((response) => {
        this.processData(response.data);
      });
    },
    processData(input) {
      const fetchedData = input.data;
      for (let i = 0; i < fetchedData.length; i++) {
        if (fetchedData[i].attributes.slug === this.$route.params.slug) {
          this.installation.id = fetchedData[i].id;
          this.installation.title = fetchedData[i].attributes.title;
          this.installation.dates = fetchedData[i].attributes.dates;
          this.installation.location = fetchedData[i].attributes.location;
          this.installation.credits = fetchedData[i].attributes.credits;

          // this.installation.writeup = fetchedData[i].attributes.writeup;
          //FOR REWRITING PATHS TO IMGS UPLOADED TO STRAPI
          const relPathWriteup = fetchedData[i].attributes.writeup;
          const absPathWriteup = relPathWriteup.replaceAll(
            "images/",
            "../images/"
          );
          this.installation.writeup = absPathWriteup;
        }
      }
      // console.log(`Data loaded: ${input.data}`);
    },
  },
  mounted() {
    this.processData(installationsJSON);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style-vars.scss";

.breadcrumbs {
  text-align: left;
  padding: 4rem 0 2rem 2rem;
  border-bottom: 1px solid $light-gray;
  a,
  a:visited {
    text-decoration: none;
    border-bottom: 1px solid $slate-gray;
  }
  a:hover,
  a:active {
    border-bottom: 2px solid $slate-gray;
    color: black;
  }
}
/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .breadcrumbs {
    padding-left: 0;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

.installations-content {
  h1 {
    font-size: 2.8rem;
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: bold;
  }
}
.return-to-index {
  margin-top: 6rem;
  padding: 6rem 2rem;
  border-top: 1px solid $light-gray;
}
</style>
